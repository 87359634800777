// @flow
import type { RecoilState } from 'recoil';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import type { Role } from 'generated/graphql';
import { localStoragePersisterEffect } from '../utils/recoilEffects';

export const rolesState: RecoilState<Role[]> = atom<Role[]>({
  key: 'userRoles',
  default: [],
  effects: [localStoragePersisterEffect()],
});

/** Returns the user's roles Recoil */
export const useGetUserRoles = (): Role[] => useRecoilValue(rolesState);

/** Saves the user's roles to Recoil
 * This allows retrieving the user's roles from any component in the app without using Apollo
 */
export const useSetUserRoles = (): ((roles: Role[]) => void) => {
  const setRoles = useSetRecoilState(rolesState);
  return (roles: Role[]) => (roles != null && roles.length ? setRoles(roles) : undefined);
};
