// @flow
import { useCallback, useMemo } from 'react';
import Text from 'common/ui/Text';
import { IconButton, Switch, withStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useMutation } from '@apollo/client';
import { Stack, Spacer } from 'common/ui/Layout';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { SettingsCard, SettingsCardContent, SettingsCardHeader } from './SettingsLayoutComponents';
import SettingsDropdown from 'common/SettingsDropdown';
import {
  MODALITIES,
  DEFAULT_SHOW_PICKLIST_OPTIONS_IN_EDITOR,
  DEFAULT_AUTO_FILL_COMPARISON_ENABLED,
} from 'config/constants';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { Colors } from 'styles';
import { useFeatureFlagEnabled, FF } from 'modules/feature-flags';
import type {
  UpdateDictaphonePreferencesMutation,
  UpdateDictaphonePreferencesMutationVariables,
} from 'generated/graphql';
import {
  GET_TOOL_PREFERENCES,
  UPDATE_DICTAPHONE_PREFERENCES,
  GET_ME,
  UPDATE_REPORTER_SETTINGS,
} from 'modules/Apollo/queries';
import { useToolPreferences } from 'hooks/usePreferences/index';
import { MenuSeparator } from '../ui/MenuSeparator/index';

type ReporterSettingsTabProps = {
  onClose: () => void,
  setError: (error: ?string) => void,
};

const SettingsMenuItem = withStyles({
  root: {
    '&.Mui-selected': {
      backgroundColor: 'initial',
    },
  },
})(MenuItem);

export const ReporterSettingsTab = ({
  onClose,
  setError,
}: ReporterSettingsTabProps): React$Node => {
  const [updateReporterSettings] = useMutation(UPDATE_REPORTER_SETTINGS);
  const { data } = useCurrentUser();
  const me = data?.me;
  const reporterSettings = me?.reporterSettings;
  const [isAutoImpressionsEnabled] = useFeatureFlagEnabled(FF.REPORTER_AUTO_IMPRESSIONS);
  const [isAutoFillComparisonFeatureEnabled] = useFeatureFlagEnabled(
    FF.REPORTER_AUTO_FILL_COMPARISON
  );
  const [isFocusModeFeatureEnabled] = useFeatureFlagEnabled(FF.REPORTER_FOCUS_MODE);

  const isFocusModeSettingEnabled = useMemo(() => {
    return reporterSettings?.focusMode?.enabled ?? false;
  }, [reporterSettings]);

  const [updateDictaphonePreferences] = useMutation<
    UpdateDictaphonePreferencesMutation,
    UpdateDictaphonePreferencesMutationVariables,
  >(UPDATE_DICTAPHONE_PREFERENCES);

  const [toolPreferences] = useToolPreferences();

  const showPicklistOptionsInEditor =
    toolPreferences?.dictaphone.showPicklistOptionsInEditor ??
    DEFAULT_SHOW_PICKLIST_OPTIONS_IN_EDITOR;
  const mergeFieldsSettings = reporterSettings?.mergeFieldsSettings;

  const isAutoFillComparisonEnabled =
    reporterSettings?.autoFillComparison?.enabled ?? DEFAULT_AUTO_FILL_COMPARISON_ENABLED;

  const toggleShowPicklistOptionsInEditor = useCallback(
    async (evt: empty) => {
      if (toolPreferences == null) return;

      const updatedResponse = {
        ...toolPreferences.dictaphone,
        showPicklistOptionsInEditor: !showPicklistOptionsInEditor,
      };

      await updateDictaphonePreferences({
        variables: { showPicklistOptionsInEditor: !showPicklistOptionsInEditor },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDictaphonePreferences: {
            __typename: 'DictaphonePreferences',
            ...updatedResponse,
          },
        },
        update: (proxy) => {
          proxy.writeQuery({
            query: GET_TOOL_PREFERENCES,
            data: {
              toolPreferences: {
                ...toolPreferences,
                dictaphone: updatedResponse,
              },
            },
          });
        },
      });
    },
    [showPicklistOptionsInEditor, toolPreferences, updateDictaphonePreferences]
  );

  const handleSaveSettings = useCallback(
    async ({
      triggerWord = reporterSettings?.triggerWord,
      styles = reporterSettings?.styles,
      cursorStyle = reporterSettings?.cursorStyle,
      lineIndicator = reporterSettings?.lineIndicator,
      mergeFieldsSettings = reporterSettings?.mergeFieldsSettings,
      aiMode = reporterSettings?.aiMode,
      impressionGenerator = reporterSettings?.impressionGenerator,
      autoFillComparison = reporterSettings?.autoFillComparison,
      focusMode = reporterSettings?.focusMode,
    }) => {
      await updateReporterSettings({
        optimisticResponse: {
          __typename: 'Mutation',
          updateReporterSettings: {
            __typename: 'ReporterSettings',
            triggerWord,
            styles,
            cursorStyle,
            lineIndicator,
            mergeFieldsSettings,
            aiMode,
            impressionGenerator,
            autoFillComparison,
            focusMode,
          },
        },
        update: (proxy) => {
          proxy.writeQuery({
            query: GET_ME,
            data: {
              me: {
                ...me,
                reporterSettings: {
                  __typename: 'ReporterSettings',
                  triggerWord,
                  styles,
                  cursorStyle,
                  lineIndicator,
                  mergeFieldsSettings,
                  aiMode,
                  impressionGenerator,
                  autoFillComparison,
                  focusMode,
                },
              },
            },
          });
        },
        variables: {
          triggerWord,
          styles,
          cursorStyle,
          lineIndicator,
          mergeFieldsSettings,
          aiMode,
          impressionGenerator,
          autoFillComparison,
          focusMode,
        },
      });
    },
    [
      me,
      reporterSettings?.aiMode,
      reporterSettings?.cursorStyle,
      reporterSettings?.impressionGenerator,
      reporterSettings?.lineIndicator,
      reporterSettings?.mergeFieldsSettings,
      reporterSettings?.styles,
      reporterSettings?.triggerWord,
      reporterSettings?.autoFillComparison,
      reporterSettings?.focusMode,
      updateReporterSettings,
    ]
  );

  return (
    <SettingsCard>
      <SettingsCardHeader>
        <Stack alignY="center">
          <Text variant="display2">Reporter Settings</Text>
          <Spacer />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </SettingsCardHeader>

      <SettingsCardContent>
        <Stack vertical space="medium">
          <Stack vertical space="small">
            <Text variant="display1" display="block">
              General
            </Text>
            <Stack alignY="center">
              <Text>Show picklist options in the Editor</Text>
              <Spacer />
              <Switch
                data-testid="picklist-editor-switch"
                checked={showPicklistOptionsInEditor}
                onChange={toggleShowPicklistOptionsInEditor}
              />
            </Stack>
            {isAutoFillComparisonFeatureEnabled && (
              <Stack alignY="center">
                <Text>Automatically populate Comparison field when prior studies are reviewed</Text>
                <Spacer />
                <Switch
                  data-testid="auto-fill-comparison-switch"
                  checked={isAutoFillComparisonEnabled}
                  onChange={(event) => {
                    handleSaveSettings({
                      autoFillComparison: {
                        ...reporterSettings?.autoFillComparison,
                        enabled: event.target.checked,
                      },
                    });
                  }}
                />
              </Stack>
            )}
            <MenuSeparator />
          </Stack>
          <Stack vertical space="small">
            <Text variant="display1" display="block">
              Navigation
            </Text>
            <Stack vertical>
              <Stack alignY="center">
                <Text>Include merge fields in tab navigation</Text>
                <Spacer />
                <Switch
                  data-testid="merge-field-navigation-switch"
                  checked={!mergeFieldsSettings?.ignoreNavigation}
                  onChange={(event) => {
                    handleSaveSettings({
                      mergeFieldsSettings: {
                        ...reporterSettings?.mergeFieldsSettings,
                        ignoreNavigation: !event.target.checked,
                      },
                    });
                  }}
                />
              </Stack>
              <Stack alignY="center">
                <Text>Ignore merge fields while identifying first input field in report</Text>
                <Spacer />
                <Switch
                  data-testid="merge-field-default-selection-switch"
                  onChange={() => {
                    handleSaveSettings({
                      mergeFieldsSettings: {
                        ...mergeFieldsSettings,
                        ignoreDefaultSelection: !mergeFieldsSettings?.ignoreDefaultSelection,
                      },
                    });
                  }}
                  checked={mergeFieldsSettings?.ignoreDefaultSelection}
                />
              </Stack>
            </Stack>
          </Stack>
          <MenuSeparator />
          {isAutoImpressionsEnabled && (
            <Stack vertical space="small">
              <Text variant="display1" display="block">
                Impression Generation
              </Text>
              <Stack alignY="center">
                <Text>Enable Impression Generation</Text>
                <Spacer />
                <Switch
                  data-testid="impression-generator-switch"
                  checked={reporterSettings?.impressionGenerator?.enabled}
                  onChange={(event) => {
                    handleSaveSettings({
                      impressionGenerator: {
                        ...reporterSettings?.impressionGenerator,
                        enabled: event.target.checked,
                      },
                    });
                  }}
                />
              </Stack>
              {reporterSettings?.impressionGenerator?.enabled === true && (
                <Stack
                  alignY="center"
                  vertical={true}
                  alignX="start"
                  space="medium"
                  css={{ paddingLeft: '16px' }}
                >
                  <Text variant="body1">
                    Trigger Impression Generation on first navigation to Impression section for the
                    following modalities:
                  </Text>
                  <SettingsDropdown
                    multiple={true}
                    value={reporterSettings?.impressionGenerator?.modalities}
                    onSelectedChange={(value) => {
                      // We should not ever not have an array with multiple true.
                      // Since the value is a union there is a type warning that it might not be what we expect
                      if (Array.isArray(value)) {
                        handleSaveSettings({
                          impressionGenerator: {
                            ...reporterSettings?.impressionGenerator,
                            modalities: value,
                          },
                        });
                      }
                    }}
                    data-testid="impression-modalities-dropdown"
                  >
                    {MODALITIES.map((option) => (
                      <SettingsMenuItem
                        id={`select-impression-modality-item-${option.value}`}
                        value={option.value}
                        key={option.value}
                        data-testid={`${option.value}-impression-modality-item`}
                      >
                        <Checkbox
                          color="primary"
                          css={`
                            color: ${Colors.blue4};
                          `}
                          checked={reporterSettings?.impressionGenerator?.modalities.includes(
                            option.value
                          )}
                        />
                        {option.name}
                      </SettingsMenuItem>
                    ))}
                  </SettingsDropdown>
                </Stack>
              )}
            </Stack>
          )}
          {isFocusModeFeatureEnabled && (
            <>
              <MenuSeparator />
              <Stack vertical space="small">
                <Text variant="display1" display="block">
                  Focus Mode
                </Text>
                <Stack alignY="center">
                  <Text>Show Focus Mode toggle in Reporter</Text>
                  <Spacer />
                  <Switch
                    data-testid="focus-mode-switch"
                    checked={isFocusModeSettingEnabled}
                    onChange={(event) => {
                      handleSaveSettings({
                        focusMode: {
                          ...reporterSettings?.focusMode,
                          enabled: event.target.checked,
                        },
                      });
                    }}
                  />
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </SettingsCardContent>
    </SettingsCard>
  );
};
