// @flow
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMutation } from '@apollo/client';
import { LINK_AUTHENTICATOR } from 'modules/Apollo/queries';
import type {
  LinkAuthenticatorMutation,
  LinkAuthenticatorMutationVariables,
} from 'generated/graphql';
import type { ApolloError, FetchResult } from '@apollo/client';

type useLinkAuthenticatorType = {
  linkAuthenticator: (
    totp: string,
    password: string
  ) => Promise<FetchResult<LinkAuthenticatorMutation>>,
  data: ?LinkAuthenticatorMutation,
  loading: boolean,
  error: ?ApolloError,
};

const useLinkAuthenticator = (): useLinkAuthenticatorType => {
  const { data: user, refetch } = useCurrentUser();
  const [linkAuthenticatorMutation, { data, loading, error }] = useMutation<
    LinkAuthenticatorMutation,
    LinkAuthenticatorMutationVariables,
  >(LINK_AUTHENTICATOR);

  const linkAuthenticator = async (totp: string, password: string) => {
    return linkAuthenticatorMutation({
      variables: {
        userId: user?.me?.id,
        password,
        totp,
      },
      onCompleted(): void {
        refetch();
      },
    });
  };

  return { linkAuthenticator, data, loading, error };
};

export default useLinkAuthenticator;
