// @flow
import { useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Input from 'common/ui/Input';
import Text from 'common/ui/Text';
import { Stack } from '../ui/Layout';
import { Colors } from 'styles';
import { Button } from 'common/ui/Button';
import { useMutation } from '@apollo/client';
import type {
  ToggleMultiFactorAuthMutation,
  ToggleMultiFactorAuthMutationVariables,
} from 'generated/graphql';
import { TOGGLE_MULTI_FACTOR_AUTH } from 'modules/Apollo/queries';
import { logger } from 'modules/logger';
import CircularProgress from '@material-ui/core/CircularProgress';

const MFADialog = ({
  show,
  onSuccess,
  multiFactorAuthEnabled,
  onClose,
}: {
  show: boolean,
  multiFactorAuthEnabled: boolean,
  onSuccess: (boolean) => void,
  onClose: () => void,
}): React$Node => {
  const [authenticatorCode, setAuthenticatorCode] = useState('');
  const [password, setPassword] = useState('');
  const [toggleMultiFactorAuth, { error, loading }] = useMutation<
    ToggleMultiFactorAuthMutation,
    ToggleMultiFactorAuthMutationVariables,
  >(TOGGLE_MULTI_FACTOR_AUTH);

  const onSubmit = async (e: Event): Promise<void> => {
    e.preventDefault();
    await toggleMultiFactorAuth({
      variables: {
        multiFactorAuthEnabled: multiFactorAuthEnabled,
        password,
        totp: authenticatorCode,
      },
      onCompleted(): void {
        setPassword('');
        onSuccess(multiFactorAuthEnabled);
      },
      onError: (error) => {
        logger.debug(error);
      },
    });
  };

  return (
    <Dialog
      maxWidth="md"
      open={show}
      onClose={onClose}
      BackdropProps={{ invisible: false }}
      disableBackdropClick={true}
      data-testid="mfa-dialog"
    >
      <DialogContent>
        <Stack css="padding: 20px 5px 25px 5px; width: 295px" vertical>
          <Text variant="display2">Let's verify it's you.</Text>
          <form onSubmit={onSubmit}>
            <Stack css="margin-top: 12px" vertical>
              <label htmlFor="password" css="margin-left: 1rem; font-size: 12px;">
                Password
              </label>
              <Input
                as="input"
                autoComplete="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="Authenticator Code"
                css="height: 40px; margin-top: 5px"
                id="password"
              />
            </Stack>
            <Stack css="margin-top: 12px" vertical>
              <label
                htmlFor="totp"
                css={`
                  margin-left: 1rem;
                  font-size: 12px;
                `}
              >
                Authentication Code
              </label>
              <Input
                as="input"
                autoComplete="one-time-code"
                value={authenticatorCode}
                onChange={(e) => setAuthenticatorCode(e.target.value)}
                type="text"
                name="Authenticator Code"
                css="margin-top: 5px; height: 40px;"
                id="totp"
              />
            </Stack>
            {error != null && (
              <Text
                css={`
                  margin-top: 5px;
                  color: ${Colors.red6};
                  margin-left: 10px;
                `}
              >
                Unable to verify, please try again
              </Text>
            )}
            <Stack css="margin-top: 25px">
              <Stack css="width: 50%">
                <Button
                  type="button"
                  css="width: 120px; border-color: transparent; margin-right: 5px"
                  variant="ghost"
                  onClick={(e) => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </Stack>
              <Stack css="width: 50%; display: flex; justify-content: center; height:40px;">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button css="width: 120px" type="submit" variant="primary">
                    Submit
                  </Button>
                )}
              </Stack>
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default MFADialog;
