// @flow
import { EraseAllToolScopeValues } from 'generated/graphql';
import Text from 'common/ui/Text';
import { Stack, Spacer } from 'common/ui/Layout';
import { useCallback } from 'react';
import type { EraseAllToolScope } from 'generated/graphql';
import SettingsDropdown from 'common/SettingsDropdown';
import MenuItem from '@material-ui/core/MenuItem';
import { useEraseAllToolScopeSetting } from 'hooks/useViewerSettings';
import { withStyles } from '@material-ui/core';

const ERASE_ALL_SCOPE_FIELD_LABEL = 'Erase All tool behavior';
const ERASE_ALL_SCOPE_VALUES = [
  { name: 'Erase in Viewport Only', value: EraseAllToolScopeValues.Viewport },
  { name: 'Erase All Annotations in Study', value: EraseAllToolScopeValues.Study },
];

const SettingsMenuItem = withStyles({
  root: {
    '&.Mui-selected': {
      backgroundColor: 'initial',
    },
  },
})(MenuItem);

export const EraseAllToolScopeSettings = (): React$Node => {
  const [eraseAllToolScope, setEraseAllToolScope] = useEraseAllToolScopeSetting();

  const handleEraseAllScopeChange = useCallback(
    async (eventValue: EraseAllToolScope) => {
      await setEraseAllToolScope(eventValue);
    },
    [setEraseAllToolScope]
  );

  return (
    <>
      <Stack alignY="center">
        <Text>{ERASE_ALL_SCOPE_FIELD_LABEL}</Text>
        <Spacer />
        <SettingsDropdown
          value={eraseAllToolScope || EraseAllToolScopeValues.Viewport}
          onSelectedChange={(value) => {
            // $FlowIgnore[incompatible-cast] Narrowing the type to EraseAllToolScope
            const eraseAllToolScopeValue = (value: EraseAllToolScope);
            handleEraseAllScopeChange(eraseAllToolScopeValue);
          }}
          data-testid="erase-all-preference"
        >
          {ERASE_ALL_SCOPE_VALUES.map((option) => (
            <SettingsMenuItem
              id={`erase-all-preference-menuitem-${option.value}`}
              value={option.value}
              key={option.value}
              data-testid={`${option.value}-menu-item`}
            >
              {option.name}
            </SettingsMenuItem>
          ))}
        </SettingsDropdown>
      </Stack>
    </>
  );
};
